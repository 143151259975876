import MessageMap from '../i18n';

const esDO: MessageMap = {
  DASHBOARD_TITLE: {
    DASHBOARD_TITLE_ALGOSET: 'AlgoSet',
    DASHBOARD_TITLE_ALGOSIX: 'AlgoSix',
  },

  GENERAL: {
    LOADING_HEADING: 'Procesando carga',
    LOADING_COMPLETED_HEADING: '¡Listo!',
    LOADING_DESCRIPTION: 'No debería demorar.',
    LOADING_PROGRESS_1: 'Estamos ',
    LOADING_PROGRESS_2: '% preparados.',
    LOADING_COMPLETED_DESCRIPTION:
      'Personalizamos tus resultados y están listos para ser descargados.',
    DOWNLOAD_BUTTON: 'Descargar resultados',
    COMPARE_BUTTON: 'Comparar otro archivo',
    CANCEL_BUTTON: 'Cancelar',
    RESULT_TABLE_EMPTY_HEADING: 'Todavía no hay resultados',
    RESULT_TABLE_EMPTY_DESCRIPTION: 'Tus resultados serán exhibidos aquí.',
    RESULTS_TITLE: 'Resultados',
    FILE_HEADER: 'Archivo',
    CREATED_ON_HEADER: 'Creado en',
    CREATED_BY_HEADER: 'Criado por',
    SIZE_HEADER: 'Tamaño',
    VALID_HEADER: 'Válido hasta',
    LINK_TEMPLATE: 'Descarga la plantilla aquí',
    VENDOR_ID_SELECT_LABEL: 'VendorID',
  },

  ALGOSET: {
    CARD_TITLE: 'Cargar archivos',
    CARD_TITLE_2: 'Parámetros',
    UPLOAD_CSV_TITLE: 'Sube tu archivo CSV',
    UPLOAD_FILE_TITLE: 'Sube los datos a comparar aquí',
    INPUT_LEGEND: 'Arrastra un archivo aquí o ',
    CSV_TOOLTIP: 'Datos internos. Límite máximo 51MB.',
    FILE_TOOLTIP: 'Datos externos. Límite máximo 51MB.',
    SELECT_BUTTON: 'Selecciona',
    SUBMIT_BUTTON: 'Enviar',
    DEFAULT_BUTTON: 'Patrón de uso',
    PARAMETERS_BUTTON: 'parámetros',
    HIDE_BUTTON: 'Omitir',
    SHOW_BUTTON: 'Mostrar',
    COMPANY_ID: 'Cedula de identidad / RNC',
    OWNER_FIRST_NAME: 'Nombre del propietario',
    EMAIL: 'Correo electrónico',
    COMPANY_NAME: 'Nombre Comercial',
    OWNER_LAST_NAME: 'Apellido del propietario',
    PHONE: 'Teléfono',
    ADDRESS: 'Dirección',
    NUMBER: 'Número',
    NEIGHBORHOOD: 'Barrio',
    APARTMENT: 'Complemento',
    CITY: 'Ciudad',
    STATE: 'Estado',
    ZIP_CODE: 'Código postal',
    LATITUDE: 'Latitud',
    LONGITUDE: 'Longitud',
    CANCEL_BUTTON: 'Cancelar',
    CUSTOMIZE_TITLE: 'Carregando',
    LOADING_SUBTITLE: 'Quedan hh:mm horas para que se completen los resultados.',
    ALL_SET_TITLE: '¡Todo listo!',
    ALL_SET_SUBTITLE: 'Personalizamos tus resultados y están listos para ser descargados.',
    COMPARE_BUTTON: 'Comparar otros archivos',
    DOWNLOAD_BUTTON: 'Descarga el resultado aquí',
    RESULTS_SUBTITLE: 'Consulta los últimos resultados',
    ERROR_MESSAGE_1: 'Carga un archivo con un tamaño máximo de 51MB y en formato CSV.',
    ERROR_MESSAGE_2: 'Carga un archivo con un tamaño máximo de 51MB.',
    ERROR_MESSAGE_3: 'Carga un formato de archivo válido. Aceptamos el formato CSV.',
    ERROR_MESSAGE_4: 'Carga un archivo con un tamaño máximo de 51MB y en formato TXT.',
    ERROR_MESSAGE_5: 'Carga un formato de archivo válido. Aceptamos el formato TXT.',
    ERROR_MESSAGE_6:
      'Por favor revise la plantilla. El contenido de su archivo no puede ser procesado.',
  },

  ALGOSIX: {
    STEPPER_LABEL_1: 'Productos BEESS',
    STEPPER_DESCRIPTION_1: 'Adicionar productos BEES para se comparados.',
    STEPPER_LABEL_2: 'Productos de aliados',
    STEPPER_DESCRIPTION_2: 'Adicionar los productos de aliados para se comparados.',
    CARD_TITLE_1: 'Productos BEES',
    SECTION_TITLE_1: 'Lista de productos',
    INPUT_PLACEHOLDER_1: 'Cargar archivo',
    INPUT_HINT_1: 'El tamaño máximo del archivo es de 51MB.',
    UPLOAD_FILE_BUTTON_TEXT_1: 'Cargar archivo',
    SECTION_TITLE_2: 'O adicionar desde la base de datos',
    CARD_TITLE_2: 'Productos de aliados',
    SECTION_TITLE_3: 'Lista de productos a ser comparada',
    ADD_BUTTON: 'Agregar nuevo archivo',
    BACK_BUTTON_TEXT: 'Volver',
    SUBMIT_BUTTON: 'Enviar',
    TOOLTIP_INFORMATION:
      'Lista de productos de los aliados a ser comparada con los productos BEES.',
    DATABASE_BUTTON_TEXT: 'Buscar en la base de datos',
    NEXT_BUTTON_TEXT: 'Próximo',
    ERROR_MESSAGE_1: 'Cargar un archivo con un tamaño máximo de 51MB.',
    ERROR_MESSAGE_2:
      'Cargar un archivo en formato válido. El sistema permite archivos en formato CSV.',
    ERROR_MESSAGE_3: 'El archivo deberá tener 3 columnas.',
    ERROR_MESSAGE_4:
      'El archivo deberá tener, al menos, 2 filas (1 para la definición + 1 para la información del producto).',
  },

  DATAIN: {
    COLLECTIONS_TITLE: 'Collecciones',
    SEARCH_ID_LABEL: 'Buscar ID gubernamental',
    SEARCH_SKU_LABEL: 'Buscar SKU',
    FILTER_VIEW: 'Ver filtros',
    ACCOUNTS_CARD_TITLE: 'Cuentas',
    ACCOUNTS_CARD_TOTAL: 'Total de cuentas',
    CARD_ERROR: 'Error',
    CARD_PASSED: 'Aprobado',
    CARD_NOT_PASSED: 'Desaprobado',
    DELIVERY_WINDOW_CARD_TITLE: 'Período para el envío',
    CREDIT_CARD_TITLE: 'Crédito',
    CREDIT_CARD_TOTAL: 'Total de crédito',
    INVENTORY_CARD_TITLE: 'Inventario',
    INVENTORY_CARD_TOTAL: 'Total de inventario',
    ITEMS_CARD_TITLE: 'Ítems',
    ITEMS_CARD_TOTAL: 'Total de ítems',
    PRODUCT_ASSORTMENT_CARD_TITLE: 'Variedad de productos',
    PRODUCT_ASSORTMENT_CARD_TOTAL: 'Total de variedad de productos',
    PRICE_CARD_TITLE: 'Precios',
    PRICE_CARD_TOTAL: 'Total de precios',
    COLLECTIONS_FILTERED_CNPJ: 'Cuentas, Crédito y Precios',
    COLLECTIONS_FILTERED_SKU: 'Precios, Ítems, Inventario y Variedad de productos',
    REQUIRED_FILTERS: 'Se requieren filtros',
    ERROR_DETAILS: 'Detalles del error',
    SEARCH_ERRORS_LABEL: 'Buscar error pro ID',
    COLLECTION_LABEL: 'Colección',
    SKU_LABEL: 'SKU',
    ERROR_LABEL: 'Error',
    TAX_ID_LABEL: 'ID gubernamental',
    ID_LABEL: 'ID',
    ALL_COLLECTIONS_SELECTED: 'Todas las colecciones seleccionadas',
    COLLECTIONS_SELECTED: 'colecciones seleccionadas',
    RESET_BUTTON: 'Reestablecer',
    CARD_PASSED_VALIDATION: 'Aprobado en validación',
    CARD_ERROR_PERCENTAGE: 'Porcentaje de error',
    CARD_TOTAL: 'Total',
    CARD_FILTERS_FIELDS_SELECTED: 'Columna y valor seleccionado',
    CARD_FILTERS_COLUMN: 'Columna',
    CARD_FILTERS_VALUE: 'Valor',
    COLLECTIONS_EMPTY_HEADING: 'Todavía no hay collecciones',
    COLLECTIONS_EMPTY_DESCRIPTION: 'Tus collecciones serán exhibidos aquí.',
    COLLECTION_EMPTY_HEADING: 'No hay datos de colección',
    COLLECTION_EMPTY_DESCRIPTION: 'No hay datos para mostrar.',
    TABLE_EMPTY_DESCRIPTION: 'No hay errores para mostrar.',
    TABLE_LOADING_DESCRIPTION: 'Cargando errores...',
    LOADING_HEADING: 'Procesando colleccion',
    LOADING_DESCRIPTION: 'No debería demorar.',
    COLLECTION_ERROR: 'Error al obtener datos de la colección',
    LOG_ERROR_TOO_LONG: 'Demasiado gran cantidad de errores',
    DATA_NOT_FOUND: 'Datos de colección no encontrados',
  },
};

export default esDO;
