import { useStore } from 'effector-react';
import React, { createContext } from 'react';
import { useIntl } from 'react-intl';
import { handleSetFile } from 'stores/AlgoSix/CurrentFiles/CurrentFilesEvents';
import CurrentFilesState from 'stores/AlgoSix/CurrentFiles/CurrentFilesState';
import CurrentFilesStore from 'stores/AlgoSix/CurrentFiles/CurrentFilesStore';
import { FileUploaderContextProps, FileUploaderProps } from './FileUploaderContext.d';

export const FileUploaderContext = createContext<FileUploaderProps | null>(null);

const FileUploaderProvider = ({ children }: FileUploaderContextProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { currentFiles } = useStore<CurrentFilesState>(CurrentFilesStore);
  const defaultPlaceholder = formatMessage({ id: 'ALGOSIX.INPUT_PLACEHOLDER_1' });

  const placeholder = (index?: number) => {
    if (index !== undefined) {
      if (currentFiles[index]) {
        return currentFiles[index].file ? currentFiles[index].file.name : defaultPlaceholder;
      }
    }
    return defaultPlaceholder;
  };

  const onChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const currentFile = event?.target.files && event.target.files[0];
    const fileFormat = event?.target.files && event.target.files[0].type;
    const fileSize = event?.target.files && event.target.files[0].size;

    if (currentFile && fileFormat && fileSize) {
      if (fileSize > 51000000) {
        handleSetFile({
          file: currentFile,
          hasError: true,
          errorMessage: formatMessage({ id: 'ALGOSIX.ERROR_MESSAGE_1' }),
        });
      } else if (fileFormat !== 'text/csv') {
        handleSetFile({
          file: currentFile,
          hasError: true,
          errorMessage: formatMessage({ id: 'ALGOSIX.ERROR_MESSAGE_2' }),
        });
      } else {
        handleSetFile({
          file: currentFile,
          hasError: false,
          errorMessage: '',
        });
      }
    }
    // reset target value to trigger onChange on every event
    if (event && event.target) event.target.value = '';
  };

  return (
    <FileUploaderContext.Provider value={{ placeholder, onChange }}>
      {children}
    </FileUploaderContext.Provider>
  );
};

export default FileUploaderProvider;
