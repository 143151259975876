import DesignTokensProvider from '@admin-portal-shared-components/design-tokens-provider';
import {
  createGenerateClassName,
  CssBaseline,
  MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core';
import FileUploaderProvider from 'contexts/AlgoSix/FilerUploader/FileUploaderContext';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import theme from './App.style';
import { IntlProvider } from './i18n';
import Routes from './Routes';

export default function App(): JSX.Element {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'saas-tools-switchbrew-mfe',
    seed: 'saas-tools-switchbrew-mfe',
  });
  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider generateClassName={generateClassName}>
        <IntlProvider>
          <BrowserRouter>
            <DesignTokensProvider>
              <FileUploaderProvider>
                <CssBaseline />
                <Routes />
              </FileUploaderProvider>
            </DesignTokensProvider>
          </BrowserRouter>
        </IntlProvider>
      </StylesProvider>
    </MuiThemeProvider>
  );
}
