import { createTheme } from '@material-ui/core';

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiDivider: {
      root: {
        marginBottom: '30px',
      },
    },
    MuiStepper: {
      root: {
        width: '100vw',
      },
    },
  },
});

export default theme;
