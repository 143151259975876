import MessageMap from '../i18n';

const ptBR: MessageMap = {
  DASHBOARD_TITLE: {
    DASHBOARD_TITLE_ALGOSET: 'AlgoSet',
    DASHBOARD_TITLE_ALGOSIX: 'AlgoSix',
  },

  GENERAL: {
    LOADING_HEADING: 'Processando upload',
    LOADING_COMPLETED_HEADING: 'Tudo pronto!',
    LOADING_DESCRIPTION: 'Não deve demorar muito.',
    LOADING_PROGRESS_1: 'Estamos ',
    LOADING_PROGRESS_2: '% prontos.',
    LOADING_COMPLETED_DESCRIPTION: 'Personalizamos seus resultados e está pronto para ser baixado.',
    DOWNLOAD_BUTTON: 'Baixar resultados',
    COMPARE_BUTTON: 'Comparar outro arquivo',
    CANCEL_BUTTON: 'Cancelar',
    RESULT_TABLE_EMPTY_HEADING: 'Ainda não há resultados ',
    RESULT_TABLE_EMPTY_DESCRIPTION: 'Seus resultados serão exibidos aqui.',
    RESULTS_TITLE: 'Resultados',
    FILE_HEADER: 'Arquivo',
    CREATED_ON_HEADER: 'Criado em',
    CREATED_BY_HEADER: 'Criado por',
    SIZE_HEADER: 'Tamanho',
    VALID_HEADER: 'Válido até',
    LINK_TEMPLATE: 'Baixe a planilha',
    VENDOR_ID_SELECT_LABEL: 'VendorID',
  },

  ALGOSET: {
    CARD_TITLE: 'Carregar arquivos',
    CARD_TITLE_2: 'Parâmetros',
    UPLOAD_CSV_TITLE: 'Carregar seu arquivo CSV',
    UPLOAD_FILE_TITLE: 'Carregar os dados a serem comparados aqui',
    INPUT_LEGEND: 'Arraste um arquivo aqui ou',
    CSV_TOOLTIP: 'Dados internos. Limite máximo 51MB.',
    FILE_TOOLTIP: 'Dados externos. Limite máximo 51MB.',
    SELECT_BUTTON: 'Selecionar',
    SUBMIT_BUTTON: 'Enviar',
    DEFAULT_BUTTON: 'Usar o padrão',
    PARAMETERS_BUTTON: 'parâmetros',
    HIDE_BUTTON: 'Omitir',
    SHOW_BUTTON: 'Mostrar',
    COMPANY_ID: 'CPF/CNPJ',
    OWNER_FIRST_NAME: 'Nome do proprietário',
    EMAIL: 'E-mail',
    COMPANY_NAME: 'Razão social',
    OWNER_LAST_NAME: 'Sobrenome do proprietário',
    PHONE: 'Telefone',
    ADDRESS: 'Endereço',
    NUMBER: 'Número',
    NEIGHBORHOOD: 'Bairro',
    APARTMENT: 'Complemento',
    CITY: 'Cidade',
    STATE: 'Estado',
    ZIP_CODE: 'CEP',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    CANCEL_BUTTON: 'Cancelar',
    CUSTOMIZE_TITLE: 'Customizando seus resultados',
    LOADING_SUBTITLE:
      'hh:mm horas restantes para completar. O tempo estimado pode mudar de acordo com o tamanho do arquivo e parâmetros.',
    ALL_SET_TITLE: 'Tudo pronto!',
    ALL_SET_SUBTITLE: 'Customizamos seus resultados e eles estão prontos para você baixar.',
    COMPARE_BUTTON: 'Comparar outros arquivos',
    DOWNLOAD_BUTTON: 'Baixar os resultados',
    RESULTS_SUBTITLE: 'Verifique os últimos resultados',
    ERROR_MESSAGE_1: 'Carregue um arquivo com o tamanho máximo de 51MB e no formato CSV.',
    ERROR_MESSAGE_2: 'Carregue um arquivo com o tamanho máximo de 51MB.',
    ERROR_MESSAGE_3: 'Carregue um formato de arquivo válido. Aceitamos o formato CSV.',
    ERROR_MESSAGE_4: 'Carregue um arquivo com o tamanho máximo de 51MB e no formato TXT.',
    ERROR_MESSAGE_5: 'Carregue um formato de arquivo válido. Aceitamos o formato TXT.',
    ERROR_MESSAGE_6:
      'Por favor verifique o template. O conteúdo do seu arquivo não pode ser processado.',
  },

  ALGOSIX: {
    STEPPER_LABEL_1: 'Produtos BEES',
    STEPPER_DESCRIPTION_1: 'Adicione os produtos BEES a serem comparados.',
    STEPPER_LABEL_2: 'Produtos de parceiros',
    STEPPER_DESCRIPTION_2: 'Adicione os produtos de parceiros a serem comparados.',
    CARD_TITLE_1: 'Produtos BEES',
    SECTION_TITLE_1: 'Lista de produtos',
    INPUT_PLACEHOLDER_1: 'Carregar arquivo',
    INPUT_HINT_1: 'O tamanho máximo do arquivo é 51MB.',
    UPLOAD_FILE_BUTTON_TEXT_1: 'Carregar arquivo',
    SECTION_TITLE_2: 'Ou adicione do banco de dados',
    DATABASE_BUTTON_TEXT: 'Buscar no banco de dados',
    NEXT_BUTTON_TEXT: 'Próximo',
    CARD_TITLE_2: 'Produtos parceiros',
    SECTION_TITLE_3: 'Lista de produtos a ser comparada',
    ADD_BUTTON: 'Adicionar novo arquivo',
    BACK_BUTTON_TEXT: 'Voltar',
    SUBMIT_BUTTON: 'Enviar',
    ERROR_MESSAGE_1: 'Faça o upload de um arquivo com tamanho máximo de 51MB.',
    ERROR_MESSAGE_2: 'Carregue um formato de arquivo válido. Aceitamos o formato CSV.',
    ERROR_MESSAGE_3: 'O arquivo deve conter 3 colunas.',
    ERROR_MESSAGE_4:
      'O arquivo deve conter pelo menos 2 linhas (1 para definição + 1 para informações do produto).',
  },

  DATAIN: {
    COLLECTIONS_TITLE: 'Coleções',
    SEARCH_ID_LABEL: 'Buscar por CNPJ',
    SEARCH_SKU_LABEL: 'Buscar por SKU',
    FILTER_VIEW: 'Ver filtros',
    ACCOUNTS_CARD_TITLE: 'Contas',
    ACCOUNTS_CARD_TOTAL: 'Total de contas',
    CARD_ERROR: 'Erro',
    CARD_PASSED: 'Aprovado',
    CARD_NOT_PASSED: 'Não aprovado',
    DELIVERY_WINDOW_CARD_TITLE: 'Janela de envio',
    DELIVERY_WINDOW_CARD_TOTAL: 'Total de janela de envio',
    CREDIT_CARD_TITLE: 'Crédito',
    CREDIT_CARD_TOTAL: 'Total de crédito',
    INVENTORY_CARD_TITLE: 'Inventário',
    INVENTORY_CARD_TOTAL: 'Total de inventário',
    ITEMS_CARD_TITLE: 'Itens',
    ITEMS_CARD_TOTAL: 'Total de itens',
    PRODUCT_ASSORTMENT_CARD_TITLE: 'Variedade de produtos',
    PRODUCT_ASSORTMENT_CARD_TOTAL: 'Total de variedade de produtos',
    PRICE_CARD_TITLE: 'Preços',
    PRICE_CARD_TOTAL: 'Total de preços',
    COLLECTIONS_FILTERED_CNPJ: 'Contas, Crédito e Preços',
    COLLECTIONS_FILTERED_SKU: 'Preços, Itens, Invetário e Variedade de produtos',
    REQUIRED_FILTERS: 'Se requieren filtros',
    ERROR_DETAILS: 'Detalhes do erro',
    SEARCH_ERRORS_LABEL: 'Buscar erro por ID',
    COLLECTION_LABEL: 'Coleções',
    SKU_LABEL: 'SKU',
    ERROR_LABEL: 'Erro',
    TAX_ID_LABEL: 'CNPJ',
    ID_LABEL: 'ID',
    ALL_COLLECTIONS_SELECTED: 'Todas as coleções selecionadas',
    COLLECTIONS_SELECTED: 'coleções selecionadas',
    RESET_BUTTON: 'Reiniciar',
    CARD_PASSED_VALIDATION: 'Validação aprovada',
    CARD_ERROR_PERCENTAGE: 'Porcentagem de erro',
    CARD_TOTAL: 'Total',
    CARD_FILTERS_FIELDS_SELECTED: 'Coluna e valor selecionados',
    CARD_FILTERS_COLUMN: 'Coluna',
    CARD_FILTERS_VALUE: 'Valor',
    COLLECTIONS_EMPTY_HEADING: 'Ainda não há coleções',
    COLLECTIONS_EMPTY_DESCRIPTION: 'Suas coleções serão exibidas aqui',
    COLLECTION_EMPTY_HEADING: 'Sem dados da coleção',
    COLLECTION_EMPTY_DESCRIPTION: 'Não há dados para mostrar.',
    TABLE_EMPTY_DESCRIPTION: 'Não há erros para mostrar.',
    TABLE_LOADING_DESCRIPTION: 'Carregando erros...',
    LOADING_HEADING: 'Processando coleção',
    LOADING_DESCRIPTION: 'Não deve demorar muito.',
    COLLECTION_ERROR: 'Erro ao buscar dados da coleção',
    LOG_ERROR_TOO_LONG: 'Quantidade de erros muito grande',
    DATA_NOT_FOUND: 'Dados da coleção não encontrados',
  },
};

export default ptBR;
