import MessageMap from '../i18n';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },

  DASHBOARD_TITLE: {
    DASHBOARD_TITLE_ALGOSET: 'AlgoSet',
    DASHBOARD_TITLE_ALGOSIX: 'AlgoSix',
  },

  GENERAL: {
    LOADING_HEADING: 'Processing upload',
    LOADING_COMPLETED_HEADING: 'All set!',
    LOADING_DESCRIPTION: 'It shouldn’t take too long.',
    LOADING_PROGRESS_1: 'We’re ',
    LOADING_PROGRESS_2: '% ready.',
    LOADING_COMPLETED_DESCRIPTION: 'We customized your results and it’s ready to be downloaded.',
    DOWNLOAD_BUTTON: 'Download results',
    COMPARE_BUTTON: 'Compare other file',
    CANCEL_BUTTON: 'Cancel',
    RESULT_TABLE_EMPTY_HEADING: 'No results yet',
    RESULT_TABLE_EMPTY_DESCRIPTION: 'Your results will be displayed here.',
    RESULTS_TITLE: 'Results',
    FILE_HEADER: 'File',
    CREATED_ON_HEADER: 'Created on',
    CREATED_BY_HEADER: 'Created by',
    SIZE_HEADER: 'Size',
    VALID_HEADER: 'Valid until',
    LINK_TEMPLATE: 'Download the template',
    VENDOR_ID_SELECT_LABEL: 'VendorID',
  },

  ALGOSET: {
    CARD_TITLE: 'Upload files',
    CARD_TITLE_2: 'Parameters',
    UPLOAD_CSV_TITLE: 'Upload your CSV file',
    UPLOAD_FILE_TITLE: 'Upload the file to be compared',
    INPUT_LEGEND: 'Select a file here',
    CSV_TOOLTIP: 'Internal data. Maximum limit of 51MB.',
    FILE_TOOLTIP: 'External data. Maximum limit of 51MB.',
    SELECT_BUTTON: 'Select',
    SUBMIT_BUTTON: 'Submit',
    DEFAULT_BUTTON: 'Use default',
    PARAMETERS_BUTTON: 'parameters',
    HIDE_BUTTON: 'Hide',
    SHOW_BUTTON: 'Show',
    COMPANY_ID: 'ID/Company ID',
    OWNER_FIRST_NAME: 'Owner’s first name',
    EMAIL: 'Email',
    COMPANY_NAME: 'Company name',
    OWNER_LAST_NAME: 'Owner’s last name',
    PHONE: 'Phone number',
    ADDRESS: 'Address',
    NUMBER: 'Number',
    NEIGHBORHOOD: 'Neighborhood',
    APARTMENT: 'Apartment/Suite',
    CITY: 'City',
    STATE: 'State',
    ZIP_CODE: 'ZIP code',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    RESULTS_SUBTITLE: 'Check the latest results',
    ERROR_MESSAGE_1: 'Upload a file with a maximum size of 51MB and CSV format.',
    ERROR_MESSAGE_2: 'Upload a file with a maximum size of 51MB.',
    ERROR_MESSAGE_3: 'Upload a valid file format. We support CSV format.',
    ERROR_MESSAGE_4: 'Upload a file with a maximum size of 51MB and TXT format.',
    ERROR_MESSAGE_5: 'Upload a valid file format. We support TXT format.',
    ERROR_MESSAGE_6: 'Please verify template file. Your file content cannot be processed.',
  },

  ALGOSIX: {
    STEPPER_LABEL_1: 'BEES products',
    STEPPER_DESCRIPTION_1: 'Add the BEES products to be compared.',
    STEPPER_LABEL_2: 'Partners products',
    STEPPER_DESCRIPTION_2: 'Add the partner products to be compared.',
    CARD_TITLE_1: 'BEES Products',
    SECTION_TITLE_1: 'Product list',
    INPUT_PLACEHOLDER_1: 'Upload file',
    INPUT_HINT_1: 'The maximum file size is 51MB.',
    UPLOAD_FILE_BUTTON_TEXT_1: 'Upload file',
    SECTION_TITLE_2: 'Or add from database',
    DATABASE_BUTTON_TEXT: 'Search from database',
    NEXT_BUTTON_TEXT: 'Next',
    CARD_TITLE_2: 'Partner Products',
    SECTION_TITLE_3: 'Product list to be compared',
    ADD_BUTTON: 'Add new file',
    BACK_BUTTON_TEXT: 'Back',
    SUBMIT_BUTTON: 'Submit',
    TOOLTIP_INFORMATION: 'Partners product list to be compared with BEES products.',
    ERROR_MESSAGE_1: 'Upload a file with maximum size of 51MB.',
    ERROR_MESSAGE_2: 'Upload a valid file format. We support CSV format.',
    ERROR_MESSAGE_3: 'The file should have 3 columns.',
    ERROR_MESSAGE_4:
      'The file should have at least 2 rows (1 for definition + 1 for product information).',
  },

  DATAIN: {
    COLLECTIONS_TITLE: 'Collections',
    SEARCH_ID_LABEL: 'Search Tax ID',
    SEARCH_SKU_LABEL: 'Search SKU',
    SEARCH_DELIVERY_ID_LABEL: 'Search Delivery ID',
    FILTER_VIEW: 'Filter view',
    ACCOUNTS_CARD_TITLE: 'Accounts',
    ACCOUNTS_CARD_TOTAL: 'Total accounts',
    CARD_ERROR: 'Error',
    CARD_PASSED: 'Passed',
    CARD_NOT_PASSED: 'Did not pass',
    DELIVERY_WINDOW_CARD_TITLE: 'Delivery window',
    DELIVERY_WINDOW_CARD_TOTAL: 'Total delivery windows',
    CREDIT_CARD_TITLE: 'Credit',
    CREDIT_CARD_TOTAL: 'Total credit',
    INVENTORY_CARD_TITLE: 'Inventory',
    INVENTORY_CARD_TOTAL: 'Total inventory',
    ITEMS_CARD_TITLE: 'Items',
    ITEMS_CARD_TOTAL: 'Total items',
    PRODUCT_ASSORTMENT_CARD_TITLE: 'Product assortment',
    PRODUCT_ASSORTMENT_CARD_TOTAL: 'Total product assortment',
    PRICE_CARD_TITLE: 'Prices',
    PRICE_CARD_TOTAL: 'Total prices',
    COLLECTIONS_FILTERED_CNPJ: 'Accounts, Credit and Prices',
    COLLECTIONS_FILTERED_SKU: 'Prices, Items, Inventory and Product Assortment',
    REQUIRED_FILTERS: 'Filters are required',
    ERROR_DETAILS: 'Error details',
    SEARCH_ERRORS_LABEL: 'Search error by ID',
    COLLECTION_LABEL: 'Collection',
    SKU_LABEL: 'SKU',
    ERROR_LABEL: 'Error',
    TAX_ID_LABEL: 'Tax ID',
    DELIVERY_ID_LABEL: 'Delivery ID',
    ID_LABEL: 'ID',
    ALL_COLLECTIONS_SELECTED: 'All collections selected',
    COLLECTIONS_SELECTED: 'collections selected',
    RESET_BUTTON: 'Reset',
    CARD_PASSED_VALIDATION: 'Passed on validation',
    CARD_ERROR_PERCENTAGE: 'Error percentage',
    CARD_TOTAL: 'Total',
    CARD_FILTERS_FIELDS_SELECTED: 'Column and values selected',
    CARD_FILTERS_COLUMN: 'Column',
    CARD_FILTERS_VALUE: 'Value',
    COLLECTIONS_EMPTY_HEADING: 'No collections yet',
    COLLECTIONS_EMPTY_DESCRIPTION: 'Your collections will be displayed here.',
    COLLECTION_EMPTY_HEADING: 'No collection data',
    COLLECTION_EMPTY_DESCRIPTION: 'There is no data to show.',
    TABLE_EMPTY_DESCRIPTION: 'There are no errors to show.',
    TABLE_LOADING_DESCRIPTION: 'Loading errors...',
    LOADING_HEADING: 'Loading collection',
    LOADING_DESCRIPTION: 'It shouldn’t take too long.',
    COLLECTION_ERROR: 'Error fetching collection data',
    LOG_ERROR_TOO_LONG: 'Log error too long',
    DATA_NOT_FOUND: 'Collection data not found',
  },
};

export default enUS;
