import { useHasPermission } from 'admin-portal-shared-services';

export default function useApplicationPermission(application: string): boolean | undefined {
  let applicationPermissions: string[] = [];
  switch (application) {
    case 'koala':
      applicationPermissions = [
        'SaaSToolsSwitchBrew.koala.Read',
        'SaaSToolsSwitchBrew.koala.Write',
      ];
      break;
    case 'algosix':
      applicationPermissions = [
        'SaaSToolsSwitchBrew.algosix.Read',
        'SaaSToolsSwitchBrew.algosix.Write',
      ];
      break;
    case 'algoset':
      applicationPermissions = [
        'SaaSToolsSwitchBrew.algoset.Read',
        'SaaSToolsSwitchBrew.algoset.Write',
      ];
      break;
    default:
      break;
  }
  const hasPermission = useHasPermission(applicationPermissions, 'OR');

  return application && applicationPermissions.length ? hasPermission : false;
}
