import { createStore } from 'effector';
import { handleRemoveFile, handleResetFile, handleSetFile } from './CurrentFilesEvents';
import CurrentFilesState from './CurrentFilesState';

const initialState: CurrentFilesState = {
  currentFiles: [],
};

const CurrentFilesStore = createStore(initialState)
  .on(handleSetFile, (state, params) => ({
    ...state,
    currentFiles: [
      ...state.currentFiles,
      {
        id: state.currentFiles.length + 1,
        file: params.file,
        hasError: params.hasError,
        errorMessage: params.errorMessage,
      },
    ],
  }))
  .on(handleRemoveFile, (state, params) => ({
    ...state,
    currentFiles: state.currentFiles
      .filter((file) => file.id !== params.index + 1)
      .map((file, index) => {
        return {
          ...file,
          id: index + 1,
        };
      }),
  }))
  .on(handleResetFile, (state) => ({
    ...state,
    currentFiles: initialState.currentFiles,
  }));

export default CurrentFilesStore;
