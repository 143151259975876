import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';
import useApplicationPermission from './services/host/AuthService';

const Menu = lazy(() => import('./pages/Menu/Menu'));
const AlgoSet = lazy(() => import('./pages/AlgoSet/AlgoSet'));
const AlgoSix = lazy(() => import('./pages/AlgoSix/AlgoSix'));
const AlgoChallenge = lazy(() => import('./pages/AlgoChallenge/AlgoChallenge'));
const DataIn = lazy(() => import('./pages/DataIn/DataIn'));

const Routes = (): JSX.Element => {
  return (
    <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
      <Switch>
        <Route path="/saastools-switchbrew" exact component={Menu} />
        <Route path="/saastools-switchbrew/algochallenge" exact component={AlgoChallenge} />
        <Route
          path="/saastools-switchbrew/algoset"
          exact
          component={useApplicationPermission('algoset') ? AlgoSet : Menu}
        />
        <Route
          path="/saastools-switchbrew/algosix"
          exact
          component={useApplicationPermission('algosix') ? AlgoSix : Menu}
        />
        <Route
          path="/saastools-switchbrew/datain-validator"
          exact
          component={useApplicationPermission('koala') ? DataIn : Menu}
        />
      </Switch>
    </Suspense>
  );
};
export default Routes;
