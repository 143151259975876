import { createEvent } from 'effector';

export const handleSetFile = createEvent<{
  file: File;
  hasError: boolean;
  errorMessage?: string | ((id: string) => string);
}>('handleSetFile');
export const handleRemoveFile = createEvent<{
  index: number;
}>('handleRemoveFile');
export const handleResetFile = createEvent('handleResetFile');
